import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.popover = new bootstrap.Popover(this.element, {
      container: 'body'
    })
  }

  share(event) {
    this.popover.show()
    // NOTE: I've disabled native share as it's too unpredictable
    //   // Share natively (if available)
    //   try {
    //     navigator.share({
    //       title: document.title,
    //       text: document.querySelector("meta[name='description']").content,
    //       url: document.URL
    //     });
    //   }
    //   catch(err) {
    //     this.popover.show()
    //   }
  }
}

