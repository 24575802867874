import { Controller } from "stimulus"
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/mobilefriendly'

export default class extends Controller {
  static targets = [ "label", "start", "end" ]

  connect() {
    this.picker = new Litepicker({
      element: this.labelTarget,
      // elementEnd: this.endTarget,
      startDate: this.startTarget.value,
      endDate: this.endTarget.value,
      singleMode: false,
      // allowRepick: true,
      // minDate: new Date (),
      minDate: document.querySelector("body").dataset.minDateAsIso,
      lang: document.querySelector('body').dataset.locale,
      // maxDays: 15,
      minDays: 2,
      // resetButton: () => {
      //   let btn = document.createElement('button');
      //   btn.setAttribute('class', "reset-button");
      //   let icon = document.createElement("i");
      //   icon.setAttribute('class', "fa fa-trash");
      //   btn.appendChild(icon);
      //
      //   return btn;
      // },
      tooltipText: {
        one: 'night',
        other: 'nights'
      },
      tooltipNumber: (totalDays) => {
        return totalDays - 1;
      },
      numberOfMonths: 2,
      numberOfColumns: 2,
      plugins: ['mobilefriendly']
    })

    this.picker.on('selected', (date1, date2) => {
      this.labelTarget.innerText = `${this.formatLabel(date1.dateInstance)} - ${this.formatLabel(date2.dateInstance)}`
      this.startTarget.value =  this.formatDate(date1.dateInstance)
      this.endTarget.value =  this.formatDate(date2.dateInstance)

      if (this.element.dataset.eagerSubmit != undefined) {
        this.element.closest("form").querySelector("button[type='submit']").click()
      }
    });

    if (document.querySelector('body').dataset.locale == 'ja') {
      this.picker.on('render', (ui) => {
        ui.querySelectorAll('.month-item-year').forEach(el => {
          el.parentElement.prepend(el)
          el.innerText += "年"
        })
      });
    }

    this.labelTarget.innerText = `${this.formatLabel(new Date(`${this.startTarget.value}T00:00:00`))} - ${this.formatLabel(new Date(`${this.endTarget.value}T00:00:00`))}`
  }

  open() {
    this.picker.show()
  }

  formatLabel(date) {
    const mo = new Intl.DateTimeFormat(document.querySelector('body').dataset.locale, { month: 'long' }).format(date)
    const da = new Intl.DateTimeFormat(document.querySelector('body').dataset.locale, { day: 'numeric' }).format(date)

    return `${mo} ${da}`
  }

  formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const days = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear()

    return `${year}-${month}-${days}`
  }
}
