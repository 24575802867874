import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "prompt", "input", "invalid"]

  unlock() {
    if (this.inputTarget.value == this.element.dataset.password) {
      this.contentTarget.classList.remove("d-none")
      this.promptTarget.classList.add("d-none")
    } else {
      this.invalidTarget.classList.remove("d-none")
    }
  }
}
