import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      spaceBetween: 20,
      slidesPerView: 'auto',
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
      breakpoints: {
        640: {
          spaceBetween: 30
        }
      }
    });
  }
}
