import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "value" ]

  connect() {
    this.valueTarget.value = window.bookBar[this.element.dataset.type]
  }

  decrement() {
    this.valueTarget.stepDown()
    this.dispatchEvent()
  }

  increment() {
    this.valueTarget.stepUp()

    this.dispatchEvent()
  }

  dispatchEvent() {
    window.bookBar[this.element.dataset.type] = parseInt(this.valueTarget.value)
    document.dispatchEvent(new CustomEvent(`${this.element.dataset.type}Updated`, { detail: this.valueTarget.value })) // We don't really care about the specific type here or the value - could make this customEvent name generic and remove the detail
  }
}
