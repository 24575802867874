import { Controller } from "stimulus"

export default class extends Controller {
  select() {
    const previousActiveElement = this.element.parentElement.querySelector('.active')
    if (previousActiveElement) {
      previousActiveElement.classList.remove('active')
      this.element.closest('.result').querySelector(`.result-package-list[data-id="${previousActiveElement.dataset.id}"]`).classList.add('d-none')
    }

    this.element.classList.add('active')
    this.element.closest('.result').querySelector(`.result-package-list[data-id="${this.element.dataset.id}"]`).classList.remove('d-none')
  }
}
