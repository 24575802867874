import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select" ]

  switch() {
    Turbo.visit(this.selectTarget.selectedOptions[0].getAttribute('data-url'))
  }
}
 
