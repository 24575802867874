import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "apply", "reset", "label" ]

  connect() {
    this.changed = []
  }

  update(event) {
    const filter = event.currentTarget.dataset.filterOption
    if (this.changed.includes(filter)) {
      this.changed.splice(this.changed.findIndex(key => key == filter), 1)
    } else {
      this.changed.push(filter)
    }

    if (this.changed.length > 0) {
      this.applyTarget.classList.remove('d-none')
    } else {
      this.applyTarget.classList.add('d-none')
    }

    setTimeout(() => {
      this.labelTarget.closest("button").click()
    }, 50)
  }

  reset() {
    const options = document.querySelector("form.simple_form.booking").elements.booking_filters.options
    Array.from(options).forEach(option => option.selected = false)

    document.querySelector("form.simple_form.booking button[type='submit']").click()
  }
  apply() {
    const form = document.querySelector("form.simple_form.booking")
    this.changed.forEach(change => {
      const formOption = Array.from(form.elements.booking_filters.options).find((element) => element.value == change)
      if (formOption.selected) {
        formOption.selected = false
      } else {
        formOption.selected = true
      }
    })
    document.querySelector("form.simple_form.booking button[type='submit']").click()
  }
}
