import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      spaceBetween: 20,
      slidesPerView: 'auto',
      freeMode: {
        enabled: true,
        sticky: true
      }
    });
  }
}
