import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // const flashType = bodyData.flashType
    // const flashMessage = bodyData.flashMessage

    new Toast({type: 'success', title: I18n.t('forms.generic_success.title'), content: this.element.dataset.message })

    // if (flashType) {
    //   if (flashType == 'notice') {
    //     new Toast({type: 'success', title: 'Success', content: flashMessage })
    //   } else {
    //     new Toast({type: 'error', title: 'Error', content: flashMessage })
    //   }
    // }
  }
}
