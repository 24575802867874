import I18n from 'i18n-js/index.js.erb'
import "@hotwired/turbo-rails"
import Toast from "utils/toast"
// import SwiperCore, { Autoplay } from 'swiper'
// import Swiper bundle with all modules installed
// TODO: Change this to only required modules
import Swiper from 'swiper/bundle'
// import SwiperCore, { Autoplay } from 'swiper'
// SwiperCore.use([Autoplay])
window.Swiper = Swiper
window.I18n = I18n
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle")

import "controllers"

// // import 'lazysizes'
// // import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
document.addEventListener("turbo:load", function() {
  const bodyData = document.querySelector('body').dataset

  // Register VirtualPageview to GTM
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ 'event': 'VirtualPageview' })

  // Register PageRead to GTM
  setTimeout(() => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({ 'event': 'PageRead' })
  }, 20000)

  window.bookBar = {}
  window.bookBar.infants = bodyData.infants
  window.bookBar.children = bodyData.children
  window.bookBar.adults = bodyData.adults
  I18n.locale = bodyData.locale

  setTimeout(() => {
    document.querySelectorAll("[data-controller='guest-selector']").forEach(function (el) {
      window.Stimulus.getControllerForElementAndIdentifier(el, "guest-selector").update()
    })
  }, 1000)

  var header = document.querySelector('header')
  window.addEventListener('scroll', function() {
    var scrollPosition = window.scrollY

    if (scrollPosition >= 30) {
      header.classList.add('stuck')
    } else {
      header.classList.remove('stuck')
    }
  });

  const flashType = bodyData.flashType
  const flashMessage = bodyData.flashMessage
  if (flashType) {
    if (flashType == 'notice') {
      new Toast({type: 'success', title: I18n.t('forms.generic_success.title'), content: flashMessage })
    } else {
      new Toast({type: 'error', title: I18n.t*('forms.generic_error.title'), content: flashMessage })
    }
  }

  // Main Menu Pane (Tab) Navigation
  const triggerTabList = [].slice.call(document.querySelectorAll('.header-menu-bar-nav button'))
  triggerTabList.forEach(function (triggerEl) {
    const panel = document.getElementById(triggerEl.dataset.target)
    // Close offCanvas onload (incase user has navigated with Turbo
    panel.classList.remove("active")
    triggerEl.classList.remove('hover')

    triggerEl.addEventListener('mouseover', function (event) {
      panel.classList.add("active")
      triggerEl.classList.add('hover')
      triggerEl.addEventListener('mouseout', function (event) {
        if (event.relatedTarget != panel){
          panel.classList.remove("active")
          triggerEl.classList.remove('hover')
        }
        panel.addEventListener('mouseout', function(event){
          if (!panel.contains(event.relatedTarget)){
            panel.classList.remove("active")
            triggerEl.classList.remove('hover')
          }
        })
      })
    })
  })

  // Close popovers on outside click
  document.querySelector('body').addEventListener('click', function(e) {
    var in_popover = e.target.closest(".popover");

    if (!in_popover) {
      var popovers = document.querySelectorAll('.popover.show');

      if (popovers[0]) {
        var triggler_selector = `[aria-describedby=${popovers[0].id}]`;

        if (!e.target.closest(triggler_selector)) {
          let the_trigger = document.querySelector(triggler_selector);
          if (the_trigger) {
            bootstrap.Popover.getInstance(the_trigger).hide();
          }
        }
      }
    }
  });
});

document.addEventListener("turbo:before-cache", function() {
  document.getElementById('offcanvasExample').classList.remove('show')
  if (document.contains(document.querySelector(".modal-backdrop"))) {
    document.querySelector(".modal-backdrop").remove();
  }
})
