import { Controller } from "stimulus"

export default class extends Controller {
  update() {
    const form = document.querySelector("form.simple_form.booking")

    form.elements.booking_sort.value = event.currentTarget.value
    document.querySelector("form.simple_form.booking button[type='submit']").click()
  }
}
