import { Controller } from "stimulus"
import { post } from '@rails/request.js'
window.accounting = require("accounting")

export default class extends Controller {
  connect() {
    if (window.fx == null) {
      window.fx = require("money")
      window.fx.settings = {
        from : "JPY",
        to : document.querySelector("body").dataset.currency
      }
      window.fx.base = "USD"
      window.fx.rates = JSON.parse(document.querySelector("body").dataset.currencies)
    }
  }

  update(event) {
    document.querySelector("body").setAttribute('data-currency', event.currentTarget.value.toUpperCase())

    window.fx.settings = {
      from : "JPY",
      to : document.querySelector("body").dataset.currency
    }

    document.querySelectorAll("[data-controller='currency']").forEach(function (element) {
      element.dispatchEvent(new CustomEvent('currency:recalculate'))
    })
    post('/settings', { body: JSON.stringify({ name: 'currency', value: document.querySelector("body").dataset.currency }) })
  }
}
