import { Controller } from "stimulus"

// TODO: Fix this duplication from room-search-auto-submit_controller
export default class extends Controller {
  static targets = [ "popoverTrigger" ]

  submit(event) {
    if (this.form_is_filled()) {
      const popover = document.querySelector(".popover-guests")
      document.querySelector("form.simple_form.booking button[type='submit']").click()
      if (popover) {
        setTimeout(() => {
        this.popoverTriggerTarget.click()
        }, 50)
      }
    }
  }

  form_is_filled() {
    if (this.form_is_filled_with_dates() && this.form_is_filled_with_guests()) {
      return true
    } else {
      if (this.form_is_filled_with_dates()) {
        new Toast({ type: 'error', title: 'Missing Data', content: 'Please enter your guests'})
        return false
      } else {
        new Toast({ type: 'error', title: 'Missing Data', content: 'Please enter your desired dates'})
        return false
      }
    }
  }

  form_is_filled_with_dates() {
    const elements = document.querySelector('form.simple_form.booking').elements

    return elements.booking_checkIn.value != '' && elements.booking_checkIn.value != ''
  }

  form_is_filled_with_guests() {
    const elements = document.querySelector('form.simple_form.booking').elements

    return ![elements.booking_infants, elements.booking_children, elements.booking_adults].every( element  => element.value == '' || element.value == "0" )
  }
}
