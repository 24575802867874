import { Controller } from "stimulus"

export default class extends Controller {
  open(event) {
    var galleryId = `room-category-type-gallery-${event.currentTarget.dataset.id}`

    var gallery = document.getElementById(galleryId)

    if (gallery) {
      console.log("Opening existing Gallery")
      var myModal = new bootstrap.Modal(gallery)
      myModal.show()
    } else {
      console.log("Opening new Gallery")
      var gallery = document.querySelector("#room-category-type-gallery-placeholder").cloneNode(true)

      gallery.id = galleryId
      gallery.querySelector("turbo-frame").setAttribute("src", event.currentTarget.dataset.url)
      document.body.appendChild(gallery)

      setTimeout(() => {
        var myModal = new bootstrap.Modal(gallery)
        myModal.show()
      }, 50);
    }
  }
}

