import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    this.element.classList.add('open')
    this.element.querySelector('.result-choice:not(.unavailable, .d-none)').click()

    window.Stimulus.getControllerForElementAndIdentifier(this.element.querySelector('.swiper-choice-list'), "swiper-choices").swiper.update()
  }
}
