import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      }
      // //slidesPerView: 'auto',
      // slidesPerView: 2,
      // loop: true,
      // // loopedSlides: 22,
      // // loopAdditionalSlides: 10,
      // autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false
      // },
      // breakpoints: {
      //   640: {
      //     slidesPerView: 4,
      //     spaceBetween: 40
      //   }
      // }
    });
  }
}
