import { Controller } from "stimulus"
import { get } from '@rails/request.js'
import AdyenCheckout from '@adyen/adyen-web'

export default class extends Controller {
  static targets = [ "container", "policyCheckbox", "submitButton" ]

  async connect() {
    let locale = "en-US"

    if (I18n.locale == "ja") {
      locale = "ja-JP"
    }

    this.configuration = {
      environment: document.querySelector("meta[name='adyen-env']").getAttribute("content"),
      clientKey: document.querySelector("meta[name='adyen-client-key']").getAttribute("content"),
      session: {
        id: this.element.dataset.sessionId,
        sessionData: this.element.dataset.sessionData
      },
      beforeSubmit: (data, component, actions) => {
        if (this.policyCheckboxTarget.checked) {
          // this.policyCheckboxTarget.enabled = false
          this.dropinComponent.elementRef.setStatus('loading')

          actions.resolve(data)
          this.createPoller()
        } else {
          new Toast({ type: 'error', title: I18n.t('forms.policy_error.title'), content: I18n.t('forms.policy_error.message')})
          actions.reject()
        }
      },
      // Used for redirects.. only(?)
      onPaymentCompleted: (result, component) => {
        console.log("Payment Completed.")
        console.info(result, component)

        this.createPoller()
      },
      onError: (error, component) => {
        new Toast({ type: 'error', title: I18n.t('forms.payment_error.title'), content: I18n.t('forms.payment_error.message')})
        console.error(error.name, error.message, error.stack, component);
      },
      setStatusAutomatically: false,
      locale: locale
    }

    this.checkout = await AdyenCheckout(this.configuration)

    if (this.element.dataset.redirectResult) {
      // Handle redirect from 3rd party payment provider
      this.checkout.submitDetails({ details: { redirectResult: this.element.dataset.redirectResult } })
    } else {
      this.dropinComponent = this.checkout.create('dropin').mount(this.containerTarget)
    }
  }

  async createPoller() {
    await new Promise(resolve => setTimeout(resolve, 2000)).then(() => this.doPoll())
  }

  async doPoll() {
    const response = await get(this.element.dataset.pollUrl)

    if (response.ok) {
      const body = await response.json

      if (body.success) {
        document.querySelector(".frame-booking").src = this.element.dataset.confirmationUrl
      } else {
        if (body.action) {
          this.dropinComponent.handleAction(body.action)
        } else  {
          new Toast({ type: 'error', title: I18n.t('forms.payment_error.title'), content: I18n.t('forms.payment_error.message')})
          this.dropinComponent.elementRef.setStatus('ready')
        }
      }
    } else {
      this.createPoller()
    }
  }
}
