import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  expand(event) {
    var elements = [].slice.call(this.element.querySelectorAll('.js-expandable'))
    elements.map(function (element) {
      element.classList.remove('d-none')
    })

    this.buttonTarget.classList.add('d-none')
  }
}
