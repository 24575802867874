import { Controller } from "stimulus"
import Colcade from 'colcade'

export default class extends Controller {
  connect() {
    new Colcade(this.element, {
      columns: '.grid-col',
      items: '.grid-item'
    })
  }
}
