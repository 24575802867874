import { Controller } from "stimulus"
window.accounting = require("accounting")

export default class extends Controller {
  connect() {
    if (window.fx == null) {
      window.fx = require("money")
      window.fx.settings = {
        from : "JPY",
        to : document.querySelector("body").dataset.currency
      }
      window.fx.base = "USD"
      window.fx.rates = JSON.parse(document.querySelector("body").dataset.currencies)
    }

    this.calculate()
    new bootstrap.Tooltip(this.element, { sanitize: false })
  }

  calculate() {
    // TODO: This should be statically defined somewhere
    let currencyFormats = {
      "JPY" : {
        symbol: "¥",
        precision: 0
      },
      "AUD" : {
        symbol: "$"
      },
      "USD" : {
        symbol: "$"
      },
      "GBP" : {
        symbol: "£"
      },
      "CNY" : {
        symbol: "¥"
      },
      "HKD" : {
        symbol: "$"
      },
      "SGD" : {
        symbol: "$"
      },
      "TWD" : {
        symbol: "元"
      },
      "NZD" : {
        symbol: "$"
      },
      "EUR" : {
        symbol: "€"
      },
      "CAD" : {
        symbol: "$"
      },
      "THB" : {
        symbol: "฿",
        format: '%v%s',
        precision: 0
      },
      "KRW" : {
        symbol: "₩",
        precision: 0
      },
      "IDR" : {
        symbol: "Rp",
        precision: 0
      },
      "MYR" : {
        symbol: "RM"
      },
      "RUB" : {
        symbol: "₽",
        format: '%v%s',
        decimal : ",",
        thousand: "."
      },
      "PHP" : {
        symbol: "₱"
      },
      "VND" : {
        symbol: "₫",
        format: '%v%s',
        decimal : ",",
        thousand: ".",
        precision: 0
      },
      "KHR" : {
        symbol: "៛",
        precision: 0
      },
      "INR" : {
        symbol: "₹"
      },
      "AED" : {
        symbol: "د.إ"
      },
      "CHF" : {
        symbol: "fr."
      }
    }

    if (window.fx.settings.to != "JPY") {
      let convertedCurrencyValue = window.fx.convert(this.element.dataset.price)

      this.element.innerHTML = accounting.formatMoney(convertedCurrencyValue, currencyFormats[window.fx.settings.to])
    } else {
      this.element.innerHTML = accounting.formatMoney(this.element.dataset.price, currencyFormats[window.fx.settings.to])
    }
  }
}
