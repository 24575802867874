import { Controller } from "stimulus"

export default class extends Controller {
  toggle() {
    if (this.element.classList.contains("active")) {
      this.element.classList.remove("active")
    } else {
      this.element.classList.add("active")
    }
  }
}
