import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    const swiper = new Swiper(this.element.querySelector('.room-category-large-gallery-thumbs'), {
      slidesPerView: 'auto'
    });
    const swiper2 = new Swiper(this.element.querySelector('.room-category-large-gallery-main'), {
      spaceBetween: 10,
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    this.element.addEventListener('keydown', (event) => {
      switch (event.keyCode) {
        case 37:
          swiper2.slidePrev()
          break;
        case 39:
          swiper2.slideNext()
          break;
          // case 27:
          //   bootstrap.Modal.getInstance(this.element).close()
          //   break;
          // default:
      }
    })


    // const swiper = new Swiper(this.element, {
    //   spaceBetween: 30,
    //   pagination: {
    //     el: ".swiper-pagination",
    //     dynamicBullets: true,
    //   }
    //   // //slidesPerView: 'auto',
    //   // slidesPerView: 2,
    //   // loop: true,
    //   // // loopedSlides: 22,
    //   // // loopAdditionalSlides: 10,
    //   // autoplay: {
    //   //   delay: 2500,
    //   //   disableOnInteraction: false
    //   // },
    //   // breakpoints: {
    //   //   640: {
    //   //     slidesPerView: 4,
    //   //     spaceBetween: 40
    //   //   }
    //   // }
    // });
  }
}


// // import { Controller } from "stimulus"
// // import { FetchRequest } from '@rails/request.js'
// // import SwiperCore, { Autoplay } from 'swiper'
// // // import Swiper bundle with all modules installed
// // // TODO: Change this to only required modules
// // import Swiper from 'swiper/bundle'
// // // import SwiperCore, { Autoplay } from 'swiper'
// // // SwiperCore.use([Autoplay])
// // window.Swiper = Swiper
//
//
// export default class extends Controller {
//   connect() {
//     this.loadGallery()
//   }
//
//   open() {
//     bootstrap.Modal.getInstance(this.element).show()
//   }
//
//   async loadGallery() {
//     const request = new FetchRequest('get', this.element.dataset.target)
//     const response = await request.perform()
//     if (response.ok) {
//       const body = await response.json
//
//       this.element.querySelector('.modal-content').innerHTML = body.content
//
//       const swiper = new Swiper(this.element.querySelector('.thumb-swiper'), {
//         slidesPerView: 'auto'
//       });
//       const swiper2 = new Swiper(this.element.querySelector('.main-swiper'), {
//         spaceBetween: 10,
//         slidesPerView: 1,
//         navigation: {
//           nextEl: ".swiper-button-next",
//           prevEl: ".swiper-button-prev",
//         },
//         thumbs: {
//           swiper: swiper,
//         },
//       });
//
//       this.element.addEventListener('keydown', (event) => {
//         switch (event.keyCode) {
//           case 37:
//             swiper2.slidePrev()
//             break;
//           case 39:
//             swiper2.slideNext()
//             break;
//           // case 27:
//           //   bootstrap.Modal.getInstance(this.element).close()
//           //   break;
//           // default:
//         }
//       })
//     } else {
//       new Toast({ type: 'error', title: 'Unexpected Error', content: 'An error occurred. Please try again later.'})
//     }
//   }
// }
//
