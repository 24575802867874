import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "infants", "children", "adults" ]

  connect() {
    if (window.bookBar) {
      this.update()
    }
  }

  update() {
    this.adultsTarget.value = window.bookBar.adults
    this.childrenTarget.value = window.bookBar.children
    this.infantsTarget.value = window.bookBar.infants

    this.updateGuestsText()
  }

  updateGuestsText() {
    let data = ['adults', 'children', 'infants'].map(item => {
      return {
        value: window.bookBar[item],
        label: I18n.t(`labels.${item}`, { count: window.bookBar[item] })
      }
    })
    data = data.filter(item => item.value > 0)

    let labelText = ""
    switch (data.length) {
      case 3:
        labelText = `${data[0].label} ${I18n.t('labels.comma')} ${data[1].label} ${I18n.t('labels.and_or_comma')} ${data[2].label}`
        break;
      case 2:
        labelText = `${data[0].label} ${I18n.t('labels.and_or_comma')} ${data[1].label}`
        break;
      case 1:
        labelText = `${data[0].label}`
        break;
      default:
        labelText = 'Guests'
    }

    this.labelTarget.innerText = labelText
  }
}
